export default [
    {
        title: 'Dashboard',
        route: 'supplier-dashboard',
        action: 'page',
        resource: 'supplier-dashboard',
        icon: 'PieChartIcon',
    },
    {
        title: 'État des stocks',
        route: 'supplier-stock-availibility',
        action: 'page',
        resource: 'supplier-stock-availibility',
        icon: 'DatabaseIcon',
    },
    {
        title: 'Statistiques',
        route: 'supplier-statistics',
        action: 'page',
        resource: 'supplier-statistics',
        icon: 'BarChart2Icon',
    },
    {
        title: 'Mes Articles',
        route: 'supplier-items-list',
        action: 'page',
        resource: 'supplier-items-list',
        icon: 'ListIcon',
    },
    {
        title: 'Catalogue',
        route: 'supplier-items-catalog',
        action: 'page',
        resource: 'supplier-items-catalog',
        icon: 'ImageIcon',
    }
]
