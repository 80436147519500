
import customer from './customer'
import administrator from './administrator'
import supplier from './supplier'

// Array of sections
export default [...administrator, ...customer, ...supplier,
// {
//     title: 'Articles',
//     route: 'products-list',
//     icon: 'ListIcon',
//     action: 'page',
//     resource: 'products-list',
// },
// {
//     title: "Guide d'utilisation",
//     route: 'pages-faq',
//     icon: 'HelpCircleIcon',
//     action: 'page',
//     resource: 'faq',
// },
]
