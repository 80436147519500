<template>
  <div class="navbar-container d-flex content align-items-center">

    <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

      <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected" />
      <ProductDetailsMED v-else :product="selected" />

    </b-modal>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="28" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <b-nav-item-dropdown class="d-none d-md-block dropdown-notification mr-25" menu-class="dropdown-menu-media" right
        v-if="$can('page', 'new-order')">
        <template #button-content>
          <feather-icon :badge="basketHeaders.length" badge-classes="bg-primary" class="text-body" icon="ShoppingBagIcon"
            size="21" />
        </template>

        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              Mes Paniers
            </h4>
            <!-- <b-badge pill variant="light-primary">
              6 New
            </b-badge> -->
          </div>
        </li>

        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
          tagname="li">

          <b-link v-for="header in basketHeaders" :key="header.id">

            <b-media @click.stop="basketOnClcik(header, 'open')">
              <b-row>
                <b-col cols="9">
                  <p class="media-heading">
                    <span class="font-weight-bolder">
                      {{ header.name }}
                    </span>
                  </p>
                  <small class="notification-text">{{ header.time }}</small>
                </b-col>
                <b-col class="text-right" cols="3">
                  <b-badge pill variant="light-danger" class="delete" @click.stop="basketOnClcik(header, 'delete')">
                    <feather-icon style="width: 40px;height: 15px;" icon="Trash2Icon" />
                  </b-badge>
                </b-col>
              </b-row>
            </b-media>

          </b-link>

        </vue-perfect-scrollbar>

      </b-nav-item-dropdown>

      <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right
        v-if="$can('page', 'new-order')">
        <template #button-content>
          <feather-icon :badge="favorites.length" badge-classes="bg-warning" icon="HeartIcon" size="21"
            class="text-body" />
        </template>

        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              Mes Favoris
            </h4>
            <b-badge pill variant="light-primary">
              {{ favorites.length }} {{ [0, 1].includes(favorites.length) ? 'article' : 'articles' }}
            </b-badge>
          </div>
        </li>

        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
          tagname="li">

          <b-link v-for="fav in favorites" :key="fav.code">

            <b-media @click.stop="selected = fav; $refs['item-modal'].show();">
              <template #aside>
                <b-avatar size="32" :src="$serverpath + fav.defImage" :text="fav.description.substring(0, 2)"
                  :variant="fav.type == 'PARA' ? 'light-primary' : 'light-success'" />
              </template>
              <b-row>
                <b-col cols="9">
                  <p class="media-heading">
                    <span class="font-weight-bolder">
                      {{ fav.description }}
                    </span>
                  </p>
                  <small class="notification-text">{{ fav.type == 'PARA' ? fav.familyDescription + ' - ' +
                    fav.subFamilyDescription : fav.dciDescription + ' - ' + fav.vendorDescription }}</small>
                </b-col>
                <b-col class="text-right" cols="3">
                  <b-badge pill variant="light-danger" class="delete"
                    @click.stop="$store.dispatch('app-favorites/DELETE_FAVORITE', fav)">
                    <feather-icon style="width: 40px;height: 15px;" icon="Trash2Icon" />
                  </b-badge>
                </b-col>
              </b-row>
            </b-media>

          </b-link>

        </vue-perfect-scrollbar>

      </b-nav-item-dropdown>


      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0" v-if="cachedUserdata">
              {{ getFullName() }}
            </p>
            <span class="user-status">{{ userData.role ? userData.role : cachedUserdata.role }}</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="userData.profilePic"
            :text="['ROLE_SUPERVISOR', 'ROLE_ADMIN'].includes(cachedUserdata.role) ? '' : avatarText(userData.firstName + ' ' + userData.lastName)"
            class="badge-minimal" badge-variant="success" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'profile' }">
          <feather-icon size="16" icon="UserCheckIcon" class="mr-50" />
          <span>Profil</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" v-if="$can('page', 'sub-accounts')"
          :to="{ name: 'sub-accounts' }">
          <feather-icon size="16" icon="UsersIcon" class="mr-50" />
          <span>Utilisateurs</span>
        </b-dropdown-item>
        <b-dropdown-divider v-if="$can('page', 'sub-accounts')" />
        <b-dropdown-item link-class="d-flex align-items-center" v-if="$can('page', 'faq')" :to="{ name: 'pages-faq' }">
          <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
          <span>Guide</span>
        </b-dropdown-item>

        <b-dropdown-divider v-if="$can('page', 'faq')" />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Déconnexion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>


  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ProductDetailsMED from '@/views/product/ProductDetailsMED.vue'
import ProductDetailsPARA from '@/views/product/ProductDetailsPARA.vue'

export default {
  components: {
    DarkToggler,
    VuePerfectScrollbar,
    ProductDetailsMED,
    ProductDetailsPARA
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      avatarText: avatarText,
      selected: {},
    }
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  computed: {
    userData() {
      return this.$store.state["app-userdata"].AppActiveUser
    },
    cachedUserdata() {
      return JSON.parse(localStorage.getItem('userData'));
    },
    basketHeaders() {
      return this.$store.getters["app-baskets/getBasketHeaders"];
    },
    favorites() {
      return this.$store.getters["app-favorites/getFavorites"];
    }
  },
  methods: {
    getFullName() {
      if (this.userData.firstName) {
        if (this.userData.lastName == null || this.userData.firstName == undefined)
          return this.userData.firstName
        else
          return this.userData.firstName + " " + this.userData.lastName;
      }
      else
        return (['ROLE_ADMIN',
          'ROLE_SUPERVISOR'].includes(this.cachedUserdata.role)
          ?
          this.cachedUserdata.username : '');
    },
    async basketOnClcik(header, action) {
      if (action == 'open') {
        if (this.$router.history.current.name == 'basket')
          this.$router.replace({
            name: "basket",
            params: {
              slug: header.id
            },
          });
        else
          this.$router.push({ name: 'basket', params: { slug: header.id } });
      } else if (action == 'delete')
        await this.$store.dispatch('app-baskets/DELETE_BASKET_HEADER', header.id);
      // else {

      // }
    },
    async logout() {
      await this.$store.dispatch('app-userdata/logout').catch(error => console.log(error)).finally(() => {
        this.$ability.update(initialAbility)
        this.$router.push({ name: 'auth-login' })
        this.$forceUpdate()
      });
    },
  },
}
</script>
<style scoped>
.delete:hover {
  background-color: #82868b;
}
</style>